<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="名称">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.status" placeholder="请选择人工状态" clearable size="small">
          <el-option :key="1" label="待审核" :value="1"></el-option>
          <el-option :key="2" label="审核通过" :value="2"></el-option>
          <el-option :key="3" label="审核失败" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.verify" placeholder="请选择微信状态" clearable size="small">
          <el-option :key="1" label="待审核" :value="1"></el-option>
          <el-option :key="2" label="审核通过" :value="2"></el-option>
          <el-option :key="3" label="审核失败" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
          :data="tableData"
          ref="rfTable"
          style="width: 100%"
          size="mini"
          @selection-change="handleSelectionChange">
      <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="nick_name"
          label="名称">
      </el-table-column>
      <el-table-column
          prop="appid"
          label="appid"
          width="160">
      </el-table-column>
      <el-table-column
          prop="tag"
          label="标签"
          width="150">
      </el-table-column>
      <el-table-column
          align="center"
          prop="first_class"
          label="类目"
          width="150">
        <template slot-scope="scope">
          {{scope.row.first_class}} > {{scope.row.second_class}}
        </template>
      </el-table-column>
      <el-table-column
          prop="template_id"
          label="模板id"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          prop="version"
          label="版本"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          prop="status"
          label="人工状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.status==0" style="color: #333">待审核</div>
          <div v-if="scope.row.status==1" style="color: green">通过</div>
          <div v-if="scope.row.status==2" style="color: red">拒绝：{{scope.row.remark}}</div>
          <div v-if="scope.row.status==3" style="color: #333">已撤回</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="verify"
          label="微信状态"
          align="center"
          width="160">
        <template slot-scope="scope">
          <div v-if="scope.row.verify==0" style="color: #333">待审核</div>
          <div v-if="scope.row.verify==1" style="color: green">通过</div>
          <div v-if="scope.row.verify==2" style="color: red">拒绝：{{scope.row.remark}}</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="release"
          label="发布状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.release==0" style="color: #333">未发布</div>
          <div v-if="scope.row.release==1" style="color: green">已发布</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="apply_time"
          label="提交时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          prop="shenhe_time"
          label="审核时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          prop="release_date"
          label="发布时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="180">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="showQrcode(scope.row)">体验码</el-button>
          <el-popconfirm
              class="mg-rt-10 mg-lf-10"
              title="确定撤销审核吗？"
              v-if="scope.row.status==1 && scope.row.verify==0"
              @confirm="undoAudit(scope.row.id)">
            <el-button type="text" size="small" slot="reference">撤回</el-button>
          </el-popconfirm>
          <el-popconfirm
              class="mg-rt-10 mg-lf-10"
              title="确定审核通过吗？"
              v-if="scope.row.status==0 || scope.row.status==3"
              @confirm="agree(scope.row.id,1)">
            <el-button type="text" size="small" slot="reference">通过</el-button>
          </el-popconfirm>
          <el-button type="text" size="small" v-if="scope.row.status==0 || scope.row.status==3" @click="choose.id=scope.row.id;showModel=true">拒绝</el-button>
          <el-button type="text" size="small" v-if="scope.row.verify==0 && scope.row.status==1" @click="getStatus(scope.row.id)">状态</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 30, 40]"
      :total="search.total">
    </el-pagination>
    <el-dialog
        title="拒绝原因"
        :visible.sync="showModel"
        width="40%">
        <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入拒绝原因"
            v-model="choose.reason">
        </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModel = false" size="small">取 消</el-button>
        <el-button type="primary" @click="agree(choose.id,2,choose.reason)" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="体验码" :visible.sync="dialogQrcodeVisible" v-if="dialogQrcodeVisible" custom-class="dialog" width="340px">
      <img :src="qrcode" width="300" />
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: '',
        verify: ''
      },
      tableData: [],
      qrcode: '',
      showModel: false,
      dialogQrcodeVisible: false,
      choose: {
        id: '',
        reason: ''
      }
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
        verify: that.search.verify
      }
      this.$api.wechat.wechatAuditIndex(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    },
    agree(id,type,reason) {
      this.showLoading()
      this.$api.wechat.wechatAuditSubmit({id:id,status:type,remark:reason}, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.handleSearch()
          this.success(res.errmsg)
          this.showModel = false
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    undoAudit(id) {
      this.showLoading()
      this.$api.wechat.wechatAuditUndo({id:id}, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.handleSearch()
          this.success(res.errmsg)
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getStatus(id) {
      this.showLoading()
      this.$api.wechat.wechatAuditStatus({id:id}, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.handleSearch()
          this.success(res.errmsg)
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    showQrcode(item) {
      this.$api.wechat.wechatExperienceQrcode({id: item.info_id}, res => {
        if(res.errcode==0) {
          this.qrcode = res.data.qrcode
          this.dialogQrcodeVisible = true
        } else {
          this.fail(res.errmsg)
        }
      })
    },
  }
};
</script>
<style scoped>
</style>
